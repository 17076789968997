import React, { useState } from "react";
import PopularServicesGrid from "./PopularServicesGrid";
import { useTranslation } from "react-i18next";
import LanguageSelector from "./components/LanguageSelector";
import "./App.css";
import { toast } from "react-toastify";

const VisaApplicationForm = () => {
  const { t } = useTranslation();

  const [formData, setFormData] = useState({
    visa_type: "",
    first_name: "",
    last_name: "",
    date_of_birth: "",
    education_level: "",
    profession: "",
    marital_status: "",
    criminal_record: "",
    visited_eu_last_10_years: "",
    previous_visa_refusal: "",
    contact_number: "",
    agreeToTerms: false, // New state for terms agreement
  });

  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: type === "checkbox" ? checked : value,
    }));
  };

  const handleRadioChange = (name, value) => {
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const isFormValid = () => {
    // Check if all fields are filled and agreeToTerms is true
    return Object.entries(formData).every(([key, value]) => {
      if (key === "agreeToTerms") return value === true; // Ensure terms are accepted
      return value !== ""; // Ensure other fields are not empty
    });
  };

  // const handleSubmit = (e) => {
  //   e.preventDefault();
  //   if (!formData.agreeToTerms) {
  //     alert("Please agree to the terms and conditions before submitting.");
  //     return;
  //   }
  //   console.log(formData);
  //   // Here you would typically send the data to a server
  // };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!formData.agreeToTerms) {
      alert("Please agree to the terms and conditions before submitting.");
      return;
    }
    try {
      const apiUrl = "https://probityvisa.online/api/visa-applications";

      const response = await fetch(apiUrl, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formData),
      });

      const responseHeaders = {};
      for (const [key, value] of response.headers.entries()) {
        responseHeaders[key] = value;
      }

      if (!response.ok) {
        const errorBody = await response.text();
        throw new Error(
          `HTTP error! status: ${response.status}, body: ${errorBody}`
        );
      }

      const data = await response.json();
      // Handle successful response
      toast.success(t("success"), {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
      setFormData({
        visa_type: "",
        first_name: "",
        last_name: "",
        date_of_birth: "",
        education_level: "",
        profession: "",
        marital_status: "",
        criminal_record: "",
        visited_eu_last_10_years: "",
        previous_visa_refusal: "",
        contact_number: "",
        agreeToTerms: false,
      });
    } catch (error) {
      // console.error("Fetch error details:", {
      //   name: error.name,
      //   message: error.message,
      //   stack: error.stack,
      // });

      toast.error("Error Notification !", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
      setFormData({
        visa_type: "",
        first_name: "",
        last_name: "",
        date_of_birth: "",
        education_level: "",
        profession: "",
        marital_status: "",
        criminal_record: "",
        visited_eu_last_10_years: "",
        previous_visa_refusal: "",
        contact_number: "",
        agreeToTerms: false,
      });
      if (error instanceof TypeError) {
        console.error(
          "Network error: This could be due to CORS, network connectivity, or server availability."
        );
      }
    }
  };

  return (
    <div
      className="min-h-screen text-white flex flex-col items-center justify-center p-6"
      style={{ backgroundColor: "#141034" }}
    >
      <div className="logo" style={{ width: "300px" }}>
        <img style={{ width: "100%" }} src="image.png" />
      </div>
      <div style={{ maxWidth: "48rem", marginBottom: "40px" }}>
        <h1 className="text-4xl sm:text-3xl md:text-5xl font-bold mb-4 text-center text-white  intro__text ">
          {t("intro")}
        </h1>
        <div className="text-center">
          <p className="text-lg leading-relaxed font-normal text-white">
            {t("introTxt")}
            <br />
            {/* <span
              style={{ fontWeight: "300", color: "white" }}
              className="font-semibold underline text-indigo-300"
            >
              {t("introSubTxt")}
            </span>{" "} */}
            {/* {t("introLinkTxt")} */}
          </p>
        </div>
      </div>
      <div
        style={{ backgroundColor: "#141034" }}
        className="w-full max-w-3xl bg-indigo-800/30 border-indigo-600/30 shadow-xl rounded-lg overflow-hidden"
      >
        <div className="p-6">
          <LanguageSelector />
          <h1 className="text-3xl font-bold mb-8 text-center text-white">
            {t("title")}
            <span
              style={{
                fontSize: " 24px",
                fontFamily: " initial",
              }}
            >
              {t("spanTitle")}
            </span>
          </h1>
          <form onSubmit={handleSubmit} className="space-y-8">
            <div>
              <label className="text-lg font-semibold text-white">
                1. {t("firstQuestion")}
              </label>
              <div className="grid grid-cols-2 gap-4 mt-2">
                {[
                  { value: "american", label: t("american") },
                  { value: "british", label: t("british") },
                  { value: "australian", label: t("australian") },
                  { value: "canadian", label: t("canadian") },
                ].map((option) => (
                  <div
                    key={option.value}
                    className={`p-3 rounded-lg cursor-pointer transition-all duration-200 ${
                      formData.visa_type === option.value
                        ? "bg-indigo-600 text-white"
                        : "bg-indigo-800/50 text-white hover:bg-indigo-700/60"
                    }`}
                    onClick={() => handleRadioChange("visa_type", option.value)}
                  >
                    <div className="flex items-center space-x-2">
                      <div
                        className={`w-4 h-4 rounded-full border-2 ${
                          formData.visa_type === option.value
                            ? "border-white bg-white"
                            : "border-indigo-300"
                        }`}
                      />
                      <span>{option.label}</span>
                    </div>
                  </div>
                ))}
              </div>
            </div>

            <div className="grid grid-cols-2 gap-6">
              <div>
                <label htmlFor="first_name" className="text-white">
                  2. {t("name")}
                </label>
                <input
                  type="text"
                  id="first_name"
                  name="first_name"
                  value={formData.first_name}
                  onChange={handleInputChange}
                  className="mt-1 w-full bg-indigo-800/50 border border-indigo-600 rounded-md p-2 text-white placeholder-indigo-300"
                />
              </div>
              <div>
                <label htmlFor="last_name" className="text-white">
                  3. {t("surname")}
                </label>
                <input
                  type="text"
                  id="last_name"
                  name="last_name"
                  value={formData.last_name}
                  onChange={handleInputChange}
                  className="mt-1 w-full bg-indigo-800/50 border border-indigo-600 rounded-md p-2 text-white placeholder-indigo-300"
                />
              </div>
            </div>

            <div>
              <label htmlFor="date_of_birth" className="text-white">
                4. {t("birthday")}
              </label>
              <input
                type="date"
                id="date_of_birth"
                name="date_of_birth"
                value={formData.date_of_birth}
                onChange={handleInputChange}
                className="mt-1 w-full bg-indigo-800/50 border border-indigo-600 rounded-md p-2 text-white"
              />
            </div>

            <div>
              <label className="text-lg font-semibold text-white">
                5. {t("education")}
              </label>
              <div className="grid grid-cols-2 gap-4 mt-2">
                {[
                  { value: "higher", label: t("masters") },
                  { value: "secondary", label: t("bachelor") },
                  { value: "other", label: t("other") },
                ].map((option) => (
                  <div
                    key={option.value}
                    className={`p-3 rounded-lg cursor-pointer transition-all duration-200 ${
                      formData.education_level === option.value
                        ? "bg-indigo-600 text-white"
                        : "bg-indigo-800/50 text-white hover:bg-indigo-700/60"
                    }`}
                    onClick={() =>
                      handleRadioChange("education_level", option.value)
                    }
                  >
                    <div className="flex items-center space-x-2">
                      <div
                        className={`w-4 h-4 rounded-full border-2 ${
                          formData.education_level === option.value
                            ? "border-white bg-white"
                            : "border-indigo-300"
                        }`}
                      />
                      <span>{option.label}</span>
                    </div>
                  </div>
                ))}
              </div>
            </div>

            <div>
              <label htmlFor="profession" className="text-white">
                6. {t("professionTitle")}
              </label>
              <input
                type="text"
                id="profession"
                name="profession"
                value={formData.profession}
                onChange={handleInputChange}
                className="mt-1 w-full bg-indigo-800/50 border border-indigo-600 rounded-md p-2 text-white placeholder-indigo-300"
              />
            </div>

            <div>
              <label className="text-lg font-semibold text-white">
                7. {t("maritalStatus")}
              </label>
              <div className="grid grid-cols-2 gap-4 mt-2">
                {[
                  { value: "single", label: t("single") },
                  { value: "married", label: t("married") },
                ].map((option) => (
                  <div
                    key={option.value}
                    className={`p-3 rounded-lg cursor-pointer transition-all duration-200 ${
                      formData.marital_status === option.value
                        ? "bg-indigo-600 text-white"
                        : "bg-indigo-800/50 text-white hover:bg-indigo-700/60"
                    }`}
                    onClick={() =>
                      handleRadioChange("marital_status", option.value)
                    }
                  >
                    <div className="flex items-center space-x-2">
                      <div
                        className={`w-4 h-4 rounded-full border-2 ${
                          formData.marital_status === option.value
                            ? "border-white bg-white"
                            : "border-indigo-300"
                        }`}
                      />
                      <span>{option.label}</span>
                    </div>
                  </div>
                ))}
              </div>
            </div>

            <div>
              <label className="text-lg font-semibold text-white">
                8. {t("arrestedOrConvicted")}
              </label>
              <div className="grid grid-cols-2 gap-4 mt-2">
                {[
                  { value: "yes", label: t("yes") },
                  { value: "no", label: t("no") },
                ].map((option) => (
                  <div
                    key={option.value}
                    className={`p-3 rounded-lg cursor-pointer transition-all duration-200 ${
                      formData.criminal_record === option.value
                        ? "bg-indigo-600 text-white"
                        : "bg-indigo-800/50 text-white hover:bg-indigo-700/60"
                    }`}
                    onClick={() =>
                      handleRadioChange("criminal_record", option.value)
                    }
                  >
                    <div className="flex items-center space-x-2">
                      <div
                        className={`w-4 h-4 rounded-full border-2 ${
                          formData.criminal_record === option.value
                            ? "border-white bg-white"
                            : "border-indigo-300"
                        }`}
                      />
                      <span>{option.label}</span>
                    </div>
                  </div>
                ))}
              </div>
            </div>

            <div>
              <label className="text-lg font-semibold text-white">
                9. {t("visitEU")}
              </label>
              <div className="grid grid-cols-2 gap-4 mt-2">
                {[
                  { value: "yes", label: t("yes") },
                  { value: "no", label: t("no") },
                ].map((option) => (
                  <div
                    key={option.value}
                    className={`p-3 rounded-lg cursor-pointer transition-all duration-200 ${
                      formData.visited_eu_last_10_years === option.value
                        ? "bg-indigo-600 text-white"
                        : "bg-indigo-800/50 text-white hover:bg-indigo-700/60"
                    }`}
                    onClick={() =>
                      handleRadioChange(
                        "visited_eu_last_10_years",
                        option.value
                      )
                    }
                  >
                    <div className="flex items-center space-x-2">
                      <div
                        className={`w-4 h-4 rounded-full border-2 ${
                          formData.visited_eu_last_10_years === option.value
                            ? "border-white bg-white"
                            : "border-indigo-300"
                        }`}
                      />
                      <span>{option.label}</span>
                    </div>
                  </div>
                ))}
              </div>
            </div>

            <div>
              <label className="text-lg font-semibold text-white">
                10. {t("visaRefused")}
              </label>
              <div className="grid grid-cols-2 gap-4 mt-2">
                {[
                  { value: "yes", label: t("yes") },
                  { value: "no", label: t("no") },
                ].map((option) => (
                  <div
                    key={option.value}
                    className={`p-3 rounded-lg cursor-pointer transition-all duration-200 ${
                      formData.previous_visa_refusal === option.value
                        ? "bg-indigo-600 text-white"
                        : "bg-indigo-800/50 text-white hover:bg-indigo-700/60"
                    }`}
                    onClick={() =>
                      handleRadioChange("previous_visa_refusal", option.value)
                    }
                  >
                    <div className="flex items-center space-x-2">
                      <div
                        className={`w-4 h-4 rounded-full border-2 ${
                          formData.previous_visa_refusal === option.value
                            ? "border-white bg-white"
                            : "border-indigo-300"
                        }`}
                      />
                      <span>{option.label}</span>
                    </div>
                  </div>
                ))}
              </div>
            </div>

            <div>
              <label htmlFor="contact_number" className="text-white">
                11. {t("contactNumber")}
              </label>
              <input
                type="tel"
                id="contact_number"
                name="contact_number"
                value={formData.contact_number}
                onChange={handleInputChange}
                className="mt-1 w-full bg-indigo-800/50 border border-indigo-600 rounded-md p-2 text-white placeholder-indigo-300"
              />
            </div>

            <div className="mt-6 bg-indigo-900/50 p-4 rounded-lg">
              <p className="mb-4 text-sm text-indigo-200">{t("termTxt")}</p>
              <div className="flex items-center">
                <div className="relative flex items-center">
                  <input
                    type="checkbox"
                    id="agreeToTerms"
                    name="agreeToTerms"
                    checked={formData.agreeToTerms}
                    onChange={handleInputChange}
                    className="opacity-0 absolute h-5 w-5 cursor-pointer"
                  />
                  <div
                    className={`bg-indigo-800 border-2 rounded-sm border-indigo-500 w-5 h-5 flex flex-shrink-0 justify-center items-center mr-2 ${
                      formData.agreeToTerms
                        ? "border-indigo-500"
                        : "border-indigo-700"
                    }`}
                    style={{ backgroundColor: "white" }}
                  >
                    <svg
                      className={`fill-current w-3 h-3 text-indigo-200 pointer-events-none ${
                        formData.agreeToTerms ? "" : "hidden"
                      }`}
                      viewBox="0 0 20 20"
                      style={{ color: "black" }}
                    >
                      <path d="M0 11l2-2 5 5L18 3l2 2L7 18z" />
                    </svg>
                  </div>
                </div>
                <label
                  htmlFor="agreeToTerms"
                  className="text-sm text-indigo-200 cursor-pointer"
                >
                  {t("termAndConditions")}
                </label>
              </div>
            </div>

            <button
              type="submit"
              className={`w-full bg-indigo-600 hover:bg-indigo-700 text-white font-bold py-2 px-4 rounded transition duration-300 ${
                !isFormValid() && "opacity-50 cursor-not-allowed"
              }`}
              disabled={!isFormValid()}
            >
              {t("submit")}
            </button>
          </form>
          <div className="info-container mt-12">
            <div className="grid grid-cols-3 gap-6 mx-auto">
              <a
                href="https://www.instagram.com/probity_visa_agency"
                target="_blank"
                rel="noopener noreferrer"
                className="flex items-center justify-center bg-indigo-800 p-4 rounded-lg text-white hover:bg-indigo-700 transition-colors duration-300"
              >
                <svg
                  className="w-10 h-10"
                  fill="currentColor"
                  viewBox="0 0 24 24"
                  aria-hidden="true"
                >
                  <path
                    fillRule="evenodd"
                    d="M12.315 2c2.43 0 2.784.013 3.808.06 1.064.049 1.791.218 2.427.465a4.902 4.902 0 011.772 1.153 4.902 4.902 0 011.153 1.772c.247.636.416 1.363.465 2.427.048 1.067.06 1.407.06 4.123v.08c0 2.643-.012 2.987-.06 4.043-.049 1.064-.218 1.791-.465 2.427a4.902 4.902 0 01-1.153 1.772 4.902 4.902 0 01-1.772 1.153c-.636.247-1.363.416-2.427.465-1.067.048-1.407.06-4.123.06h-.08c-2.643 0-2.987-.012-4.043-.06-1.064-.049-1.791-.218-2.427-.465a4.902 4.902 0 01-1.772-1.153 4.902 4.902 0 01-1.153-1.772c-.247-.636-.416-1.363-.465-2.427-.047-1.024-.06-1.379-.06-3.808v-.63c0-2.43.013-2.784.06-3.808.049-1.064.218-1.791.465-2.427a4.902 4.902 0 011.153-1.772A4.902 4.902 0 015.45 2.525c.636-.247 1.363-.416 2.427-.465C8.901 2.013 9.256 2 11.685 2h.63zm-.081 1.802h-.468c-2.456 0-2.784.011-3.807.058-.975.045-1.504.207-1.857.344-.467.182-.8.398-1.15.748-.35.35-.566.683-.748 1.15-.137.353-.3.882-.344 1.857-.047 1.023-.058 1.351-.058 3.807v.468c0 2.456.011 2.784.058 3.807.045.975.207 1.504.344 1.857.182.466.399.8.748 1.15.35.35.683.566 1.15.748.353.137.882.3 1.857.344 1.054.048 1.37.058 4.041.058h.08c2.597 0 2.917-.01 3.96-.058.976-.045 1.505-.207 1.858-.344.466-.182.8-.398 1.15-.748.35-.35.566-.683.748-1.15.137-.353.3-.882.344-1.857.048-1.055.058-1.37.058-4.041v-.08c0-2.597-.01-2.917-.058-3.96-.045-.976-.207-1.505-.344-1.858a3.097 3.097 0 00-.748-1.15 3.098 3.098 0 00-1.15-.748c-.353-.137-.882-.3-1.857-.344-1.023-.047-1.351-.058-3.807-.058zM12 6.865a5.135 5.135 0 110 10.27 5.135 5.135 0 010-10.27zm0 1.802a3.333 3.333 0 100 6.666 3.333 3.333 0 000-6.666zm5.338-3.205a1.2 1.2 0 110 2.4 1.2 1.2 0 010-2.4z"
                    clipRule="evenodd"
                  />
                </svg>
              </a>
              <a
                href="https://wa.me/355694845459"
                target="_blank"
                rel="noopener noreferrer"
                className="flex items-center justify-center bg-indigo-800 p-4 rounded-lg text-white hover:bg-indigo-700 transition-colors duration-300"
              >
                <svg
                  className="w-10 h-10"
                  fill="currentColor"
                  viewBox="0 0 24 24"
                  aria-hidden="true"
                >
                  <path
                    fillRule="evenodd"
                    d="M20.452 3.546C18.24 1.332 15.277 0 12.13 0 5.498 0 .13 5.368.13 12c0 2.11.553 4.17 1.602 5.986L.12 24l6.173-1.6c1.752.958 3.722 1.46 5.737 1.46h.005c6.63 0 12-5.368 12-12 0-3.147-1.332-6.11-3.545-8.314zm-8.323 18.453h-.004c-1.793 0-3.55-.48-5.086-1.386l-.366-.218-3.786.99 1.013-3.69-.24-.38c-1.01-1.61-1.545-3.477-1.545-5.414 0-5.582 4.54-10.122 10.126-10.122 2.7 0 5.24 1.054 7.15 2.963 1.91 1.91 2.96 4.45 2.96 7.15 0 5.583-4.544 10.126-10.127 10.126zm5.546-7.587c-.305-.15-1.802-.888-2.08-.988-.28-.1-.483-.15-.688.15-.203.3-.788.99-.965 1.19-.178.202-.355.227-.66.076-.303-.15-1.282-.472-2.443-1.507-.903-.806-1.513-1.8-1.69-2.103-.178-.303-.02-.466.133-.617.137-.134.305-.35.457-.527.153-.176.203-.3.305-.5.1-.202.05-.378-.025-.527-.076-.15-.688-1.655-.942-2.266-.25-.607-.503-.525-.69-.535-.176-.01-.38-.01-.582-.01-.203 0-.533.076-.812.378-.28.3-1.066 1.04-1.066 2.536 0 1.496 1.09 2.94 1.24 3.143.153.2 2.16 3.29 5.23 4.616.73.316 1.3.505 1.743.645.735.234 1.403.2 1.93.12.588-.086 1.802-.737 2.055-1.45.253-.71.253-1.32.177-1.445-.076-.127-.28-.202-.583-.352z"
                    clipRule="evenodd"
                  />
                </svg>
              </a>
              {/* <a
                href="https://www.facebook.com/yourpage"
                target="_blank"
                rel="noopener noreferrer"
                className="flex items-center justify-center bg-indigo-800 p-4 rounded-lg text-white hover:bg-indigo-700 transition-colors duration-300"
              >
                <svg
                  className="w-10 h-10"
                  fill="currentColor"
                  viewBox="0 0 24 24"
                  aria-hidden="true"
                >
                  <path
                    fillRule="evenodd"
                    d="M22 12c0-5.523-4.477-10-10-10S2 6.477 2 12c0 4.991 3.657 9.128 8.438 9.878v-6.987h-2.54V12h2.54V9.797c0-2.506 1.492-3.89 3.777-3.89 1.094 0 2.238.195 2.238.195v2.46h-1.26c-1.243 0-1.63.771-1.63 1.562V12h2.773l-.443 2.89h-2.33v6.988C18.343 21.128 22 16.991 22 12z"
                    clipRule="evenodd"
                  />
                </svg>
              </a> */}
              <a
                href="mailto:myvisaceo@gmail.com"
                className="flex items-center justify-center bg-indigo-800 p-4 rounded-lg text-white hover:bg-indigo-700 transition-colors duration-300"
              >
                <svg
                  className="w-10 h-10"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z"
                  />
                </svg>
              </a>
            </div>
          </div>
        </div>
      </div>
      <PopularServicesGrid></PopularServicesGrid>
      <div
        style={{ backgroundColor: "#141034" }}
        className="w-full max-w-3xl bg-indigo-800/30 border-indigo-600/30 shadow-xl rounded-lg overflow-hidden mt-8"
      >
        <div className="p-6 md:p-10">
          <h2
            style={{
              display: "flex",
              marginBottom: "4px",
              color: "rgb(163 156 156)",
              fontSize: "1.15rem",
            }}
            className="text-2xl font-bold mb-4 text-center text-white"
          >
            {t("visaCompany")}
          </h2>
          <p
            style={{
              color: "rgb(163 156 156)",
              fontSize: "1rem",
            }}
            className="text-lg leading-relaxed font-normal text-white"
          >
            {t("visaText")}
          </p>
        </div>
        {/* <div className="bg-indigo-700 p-6 md:p-10 rounded-b-lg">
          <h2 className="text-2xl font-bold mb-4 text-center text-white">
            Our Popular Services
          </h2>
          <div className="grid grid-cols-2 gap-4">
            <div className="flex items-center space-x-4 bg-indigo-800 p-4 rounded-lg">
              <svg
                className="w-8 h-8 text-white"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M7 8h10M7 12h4m1 8l-4-4H5a2 2 0 01-2-2V6a2 2 0 012-2h14a2 2 0 012 2v8a2 2 0 01-2 2h-3l-4 4z"
                />
              </svg>
              <span className="text-white font-semibold">
                Chat to a Visa Agent
              </span>
            </div>
            <div className="flex items-center space-x-4 bg-indigo-800 p-4 rounded-lg">
              <svg
                className="w-8 h-8 text-white"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M12 6.253v13m0-13C10.832 5.477 9.246 5 7.5 5S4.168 5.477 3 6.253v13C4.168 18.477 5.754 18 7.5 18s3.332.477 4.5 1.253m0-13C13.168 5.477 14.754 5 16.5 5c1.747 0 3.332.477 4.5 1.253v13C19.832 18.477 18.247 18 16.5 18c-1.746 0-3.332.477-4.5 1.253"
                />
              </svg>
              <span className="text-white font-semibold">
                Let us take care of your application
              </span>
            </div>
          </div>
        </div> */}
      </div>
    </div>
  );
};

export default VisaApplicationForm;
