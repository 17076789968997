import i18n from "i18next";
import LanguageDetectors from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";

i18n
  .use(LanguageDetectors)
  .use(initReactI18next)
  .init({
    debug: true,
    lng: "en",
    resources: {
      en: {
        translation: {
          intro: "Let us take care of your application",
          introTxt: `The process of obtaining a visa can be complicated, but our experienced team is here to assist you in handling all the paperwork and obtaining the documents you require to travel.`,
          introSubTxt: "Search for your destination ",
          introLinkTxt: "to get started.",
          title: "Fill in the form ",
          spanTitle: " (*check your visa eligibility )",
          firstQuestion: "Type of visa you are interested in:",
          //start first question
          american: "American",
          british: "Uk",
          australian: "Australian",
          canadian: "Canadian",
          //end first question
          name: "Name",
          surname: "Surname",
          birthday: "Birthday",
          education: "Education level",
          //start second question
          bachelor: "Bachelor",
          masters: "Masters",
          other: "Other",
          //end second question
          professionTitle: "Occupation / Profession title",
          maritalStatus: "Marital status",
          //start third question
          single: "Single",
          married: "Married",
          //end third question
          arrestedOrConvicted: "Have you ever been arrested or convicted?",
          //start forth question
          yes: "Yes",
          no: "No",
          //end forth question
          visitEU:
            "Have you been visiting any European country on the last 10 years?",
          visaRefused:
            "Have you ever been refused the visa you are about to apply for?",
          contactNumber:
            "Contact number where you would like to be informed about your visa eligibility.",
          submit: "Send aplication",
          termTxt:
            " Probity visa do not share data with any third parties. The information submitted in this form will only be seen by staff of Probity visa and we will only contact you in relation to the enquiry you have submitted.",
          termAndConditions:
            "I agree that I have read and understood the above",
          popularServices: "Our Popular Services",
          //Australia Features
          australiaFeatures1: "Valid from 3-12 months",
          australiaFeatures2: "Priority service available",
          australiaFeatures3: "Supporting documents may be required",
          australiaInfoText:
            "The Australia Tourist eVisitor Visa allows for short-term stays for tourism or business purposes. It is electronically linked to your passport.",
          //USA  Features
          usaFeatures1: "Valid for 10 years",
          usaFeatures2: "Priority servise available",
          usaFeatures3: "Family application option",
          usaInfoText:
            "The USA ESTA (Electronic System for Travel Authorization) allows eligible travelers to visit the United States for up to 90 days without obtaining a visa.",
          //Uk  Features
          ukFeatures1: "Valid from 3-12 months",
          ukFeatures2: "Priority servise available",
          ukFeatures3: "Supporting documents may be required",
          ukInfoText:
            "The UK Electronic Travel Authorization (ETA) is a required entry document for visa-exempt foreign nationals travelling to the United Kingdom by air or sea.",
          learnMore: "Learn more",
          close: "Close",
          visaCompany: "Probity visa",
          visaText:
            "is a registered agency, assisting travellers to obtain the relevant documents required for entry. The agency and site are not affiliated with any government body.",
          success: "Your application has been successfully submitted",
          error: "Something went wrong, please try again later!",
        },
      },
      alb: {
        translation: {
          intro: "Na lejoni të kujdesemi për aplikimin tuaj",
          introTxt: `Procesi i marrjes së vizës mund të jetë i ndërlikuar, por ekipi ynë me përvojë është këtu për t'ju ndihmuar në trajtimin e të gjitha proceseve dhe marrjen e dokumenteve që ju nevojiten për të udhëtuar.`,
          introSubTxt: "Kërkoni për destinacionin",
          introLinkTxt: " për të filluar..",
          title: " Hapi i parë i aplikimit për vizë",
          spanTitle: "",
          firstQuestion: "Lloji i vizës për të cilën interesoheni:",
          //start first question
          american: "Amerikane",
          british: "Angleze",
          australian: "Australiane",
          canadian: "Kanadeze",
          //end first question
          name: "Emër",
          surname: "Mbiemër",
          birthday: "Ditëlindja",
          education: "Arsimi",
          //start second question
          bachelor: "I mesëm",
          masters: "I lartë",
          other: "Tjetër",
          //end second question
          professionTitle: "Profesioni (profili akademik)",
          maritalStatus: "Statusi civil",
          //start third question
          single: "Beqar",
          married: "I Martuar",
          //end third question
          arrestedOrConvicted: "A keni preçedent penal?",
          //start forth question
          yes: "Po",
          no: "Jo",
          //end forth question
          visitEU:
            "A keni vizituar ndonjë nga vendet e Bashkimit Europian gjatë 10 viteve të fundit?",
          visaRefused:
            "A keni patur më parë refuzim të vizës për të cilën dëshironitë aplikoni?",
          contactNumber:
            "Numri i kontaktit në të cilin dëshironi të kontaktoheni për të vijuar me aplikimin për vizë",
          submit: "Dërgo aplikimin",
          termTxt:
            "Probity visa nuk do ndaj të dhëna me palë të treta. Informacioni i paraqitur në këtë formular do të shihet vetëm nga stafi i Probity visa dhe ne do t'ju kontaktojmë vetëm në lidhje me kërkesën që keni paraqitur.",
          termAndConditions:
            "Jam dakord per ato qe kam lexuar dhe kuptuar  më sipër",
          popularServices: "Shërbimet tona me te kerkuara",
          //Australia Features
          australiaFeatures1: "E vlefshme nga 3-12 muaj",
          australiaFeatures2: "Ofrohet shërbim prioritar",
          australiaFeatures3: "Mund të kërkohen dokumente mbështetëse",
          australiaInfoText:
            "Viza e vizitorëve turistikë e Australisë lejon qëndrime afatshkurtra për qëllime turistike ose biznesi. Është e lidhur në mënyrë elektronike me pasaportën tuaj.",
          //USA  Features
          usaFeatures1: "E vlefshme për 10 vjet",
          usaFeatures2: "Ofrohet shërbim me përparësi",
          usaFeatures3: "Opsioni i aplikimit familjar",
          usaInfoText:
            "USA ESTA (Sistemi Elektronik për Autorizimin e Udhëtimit) lejon udhëtarët e kualifikuar të vizitojnë Shtetet e Bashkuara deri në 90 ditë pa marrë vizë.",
          //Uk  Features
          ukFeatures1: "E vlefshme nga 3-12 muaj",
          ukFeatures2: "Ofrohet shërbim me përparësi",
          ukFeatures3: "Mund të kërkohen dokumente mbështetëse",
          ukInfoText:
            "Autorizimi Elektronik i Udhëtimit në Mbretërinë e Bashkuar (ETA) është një dokument i kërkuar hyrje për shtetasit e huaj të përjashtuar nga viza që udhëtojnë në Mbretërinë e Bashkuar me rrugë ajrore ose detare.",
          learnMore: "Mëso më shumë",
          close: "Mbyll",
          visaCompany: " Probity visa",
          visaText:
            "është një agjenci e regjistruar, që ndihmon udhëtarët të marrin dokumentet përkatëse të kërkuara për hyrje. Agjencia dhe faqja nuk janë të lidhura me asnjë organ qeveritar.",
          success: "Aplikimi juaj është dërguar!",
          error: "Dicka ka shkuar gabim ju lutem provoni perseri me von!",
        },
      },
    },
  });
