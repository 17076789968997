import React from "react";
import { useTranslation } from "react-i18next";

const languages = [
  { code: "en", lang: "English", img: "./us.png" },
  { code: "alb", lang: "Shqip", img: "./al.png" },
];

const LanguageSelector = () => {
  const { i18n } = useTranslation();

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
  };

  return (
    <div className="btn-container">
      {languages.map((lang) => {
        return (
          <button
            className={lang.code === i18n.language ? "selected  btn" : "btn"}
            key={lang.code}
            onClick={() => changeLanguage(lang.code)}
          >
            {/* {lang.lang} */}
            <img className="langue-img" src={lang.img} />
          </button>
        );
      })}
    </div>
  );
};

export default LanguageSelector;
